<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid grid-list-md>
            <v-layout row wrap>

                <v-flex xs12>

                    <v-radio-group v-model="company.language_id" :column="false">
                        <v-radio class="mr-5" color="lime" v-for="(lang, index) in languages" :key="index" :label="lang.lang_descricao" :value="lang.lang_id"></v-radio>
                    </v-radio-group>

                </v-flex>

                <v-flex d-flex xs12 sm8 md10 lg10 xl10>
                    <v-layout row wrap>

                        <v-flex xs12 sm12 md6 lg6 xl6>
                            <v-text-field v-model="company.comp_razaosocial" :rules="rules.razaosocialRules" label="Razão Social" outlined required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg6 xl6>
                            <v-text-field v-model="company.comp_fantasia" :rules="rules.fantasiaRules" label="Fantasia" outlined required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="company.comp_copyright" :rules="rules.copyrightRules" label="Copyright" outlined required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="company.comp_dominio" :rules="rules.dominioRules" label="Domínio" outlined required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="company.comp_google_analytics" :rules="rules.google_analyticsRules" label="Cod. Analytics" outlined></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg6 xl6>
                            <v-textarea v-model="company.comp_palavras_chaves" :rules="rules.palavras_chavesRules" :counter="180" name="palavras_chaves" label="Palavras Chaves" outlined required></v-textarea>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg6 xl6>
                            <v-textarea v-model="company.comp_descricao" :rules="rules.descricaoRules" :counter="180" name="descricao" label="Descrição" outlined required></v-textarea>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="company.comp_link_facebook" :rules="rules.link_facebookRules" name="link_facebook" label="Facebook" outlined></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="company.comp_link_instagram" :rules="rules.link_instagramRules" name="link_instagram" label="Instagram" outlined></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="company.comp_link_twitter" :rules="rules.link_twitterRules" name="link_twitter" label="Twitter" outlined></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <v-textarea v-model="company.comp_token_instagram" name="comp_token_instagram" label="Token Instagram" outlined></v-textarea>
                        </v-flex>

                        <v-flex xs12>
                            <v-textarea v-model="company.comp_texto_lgpd" name="comp_texto_lgpd" label="Texto LGPD" outlined></v-textarea>
                        </v-flex>

                        <v-flex xs12>
                            <v-textarea v-model="company.comp_link_lgpd" name="comp_link_lgpd" label="Link LGPD" outlined></v-textarea>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-switch color="lime" v-model="company.comp_status" label="Status"></v-switch>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                           <v-switch color="lime" v-model="company.comp_lgpd" label="Ativar LGPD"></v-switch>
                        </v-flex>

                        <v-flex xs12>
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                {{btnLabel}}
                            </v-btn>
                        </v-flex>

                    </v-layout>
                </v-flex>

                <v-flex d-flex xs12 sm4 md2 lg2 xl2>

                    <v-layout row wrap justify-center column>

                        <v-flex d-flex v-if="preview || company.comp_logo" justify-center>
                            <v-card>
                                <v-img v-if="preview" class="img-preview" :src="preview">
                                </v-img>

                                <v-img v-if="company.comp_logo && !preview" class="img-preview" :src="`${base_url}upload/company/${company.comp_logo}`">
                                </v-img>

                                <v-card-actions class="justify-center">
                                    <v-btn icon>
                                        <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>

                        <v-flex d-flex justify-center>
                            <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                            <v-btn v-if="!preview && !company.comp_logo" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                                Adicionar logo
                                <v-icon right dark>cloud_upload</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
  URL_BASE
} from '../../../../config/configs'

export default {
  props: {
    company: {
      require: false,
      type: Object | Array,
      default: () => {
        return {
          comp_id: '',
          language_id: 1,
          comp_razaosocial: '',
          comp_fantasia: '',
          comp_copyright: '',
          comp_dominio: '',
          comp_google_analytics: '',
          comp_obs: '',
          comp_descricao: '',
          comp_palavras_chaves: '',
          comp_link_facebook: '',
          comp_link_instagram: '',
          comp_link_twitter: '',
          comp_token_instagram: '',
          comp_status: false,
          comp_logo: '',
          comp_texto_lgpd: '',
          comp_link_lgpd: '',
          comp_lgpd: ''
        }
      }
    },
    update: {
      require: false,
      type: Boolean,
      default: false
    },
    btnLabel: {
      require: false,
      type: String,
      default: 'Salvar'
    }
  },
  created () {
    this.$store.dispatch('loadLanguages')
  },
  computed: {
    languages () {
      return this.$store.state.languages.items.data
    }
  },
  name: 'FormCompanyComponent',

  data () {
    return {
      valid: true,
      rules: {
        razaosocialRules: [
          v => !!v || 'Campo Razão Social é obrigatório',
          v => (v && v.length <= 200) || 'Razão Social precisa ter até 200 Caracteres'
        ],
        fantasiaRules: [
          v => !!v || 'Campo Fantasia é obrigatório',
          v => (v && v.length <= 200) || 'Fantasia precisa ter ate 200 Caracteres'
        ],
        copyrightRules: [
          v => !!v || 'Campo Copyright é obrigatório'
        ],
        dominioRules: [
          v => !!v || 'Campo Domínio é obrigatório'
        ],
        palavras_chavesRules: [
          v => !!v || 'Campo Palavras Chaves é obrigatório',
          v => (v && v.length <= 180) || 'Keyword deve ter até 180 caracteres'
        ],
        descricaoRules: [
          v => !!v || 'Campo Descrição é obrigatório',
          v => (v && v.length <= 180) || 'Descrição deve ter até 180 caracteres'
        ]
      },
      upload: null,
      fileName: 'Selecione uma foto',
      preview: null,
      base_url: URL_BASE
    }
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        const action = this.update ? 'updateCompany' : 'storeCompany'
        const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso'

        const formData = new FormData()

        if (this.upload !== null) { formData.append('comp_logo', this.upload) }

        formData.append('comp_id', this.company.comp_id)
        formData.append('language_id', this.company.language_id)
        formData.append('comp_razaosocial', this.company.comp_razaosocial)
        formData.append('comp_fantasia', this.company.comp_fantasia)
        formData.append('comp_copyright', this.company.comp_copyright)
        formData.append('comp_dominio', this.company.comp_dominio)
        formData.append('comp_google_analytics', this.company.comp_google_analytics)
        formData.append('comp_obs', this.company.comp_obs)
        formData.append('comp_descricao', this.company.comp_descricao)
        formData.append('comp_palavras_chaves', this.company.comp_palavras_chaves)
        formData.append('comp_link_facebook', this.company.comp_link_facebook)
        formData.append('comp_link_instagram', this.company.comp_link_instagram)
        formData.append('comp_link_twitter', this.company.comp_link_twitter)
        formData.append('comp_token_instagram', this.company.comp_token_instagram)
        formData.append('comp_status', this.company.comp_status)
        formData.append('comp_lgpd', this.company.comp_lgpd)
        formData.append('comp_texto_lgpd', this.company.comp_texto_lgpd)
        formData.append('comp_link_lgpd', this.company.comp_link_lgpd)

        this.$store.dispatch(action, formData)
          .then(() => {
            this.$swal({
              position: 'center',
              icon: 'success',
              title: msg,
              showConfirmButton: true,
              timer: 6000
            })
            this.$router.push({
              name: 'company'
            })
          })
          .catch(error => {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'Erro! Algo deu errado',
              text: error,
              showConfirmButton: true,
              timer: 6000
            })
            this.errors = error.response.data.errors
          })
      } else {
        this.$swal({
          position: 'center',
          icon: 'warning',
          title: 'Alguns campos requer sua atenção',
          text: 'Verifique os campos em vermelho',
          showConfirmButton: true,
          timer: 6000
        })
      }
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) { return }

      this.upload = files[0]
      this.fileName = this.upload.name

      this.previewImage(files[0])
    },
    previewImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.preview = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removePreview () {
      this.preview = null
      this.upload = null
    }
  },
  components: {

  }
}
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 120px;
}
</style>
