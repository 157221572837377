<template>

    <span>
    <v-layout mb-4>
      <v-flex xs10 >
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Empresa - Inclusão</h2>
      </v-flex>
      <v-flex xs2 text-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'company'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
        <form-company :btnLabel="btnLabel"></form-company>
    </span>

</template>

<script>
import FormCompanyComponent from './partial/FormCompanyComponent'

export default {
  name: 'AddCompanyComponent',
  data () {
    return {
      btnLabel: 'Incluir'
    }
  },
  methods: {

  },
  components: {
    formCompany: FormCompanyComponent
  }
}
</script>

<style scoped>

</style>
